var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "filter-period",
      class: "filter-period--" + _vm.internalOption
    },
    [
      _c("FormDropdown", {
        ref: "dropdown",
        attrs: { disabled: _vm.disabled },
        scopedSlots: _vm._u([
          {
            key: "button-content",
            fn: function() {
              return [
                _vm._v(" " + _vm._s(_vm.optionsLabels[_vm.internalOption]))
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return _vm._l(_vm.options, function(option) {
                return _c(
                  "DropdownItem",
                  {
                    key: option.value,
                    attrs: { selected: _vm.internalOption === option.value },
                    nativeOn: {
                      click: function($event) {
                        return _vm.onOptionClick(option)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(option.text) + " ")]
                )
              })
            },
            proxy: true
          }
        ])
      }),
      _vm.isCustomOption
        ? _c(
            "div",
            {
              staticClass: "d-flex flex-row flex-nowrap justify-content-around"
            },
            [
              _c("ADatepicker", {
                attrs: {
                  label: "From date",
                  disabled: _vm.disabled,
                  "date-format-options": _vm.dateFormatOptions,
                  max: _vm.internalEndTime
                },
                model: {
                  value: _vm.internalBeginTime,
                  callback: function($$v) {
                    _vm.internalBeginTime = $$v
                  },
                  expression: "internalBeginTime"
                }
              }),
              _c("ADatepicker", {
                attrs: {
                  label: "To date",
                  disabled: _vm.disabled,
                  "date-format-options": _vm.dateFormatOptions,
                  min: _vm.internalBeginTime
                },
                model: {
                  value: _vm.internalEndTime,
                  callback: function($$v) {
                    _vm.internalEndTime = $$v
                  },
                  expression: "internalEndTime"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }