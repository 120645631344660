var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    { attrs: { label: _vm.label, "label-for": _vm.control_id } },
    [
      _c("b-form-datepicker", {
        attrs: {
          "date-format-options": _vm.dateFormatOptions,
          disabled: _vm.disabled,
          id: _vm.control_id,
          min: _vm.min,
          max: _vm.max,
          "label-no-date-selected": "Select date",
          "nav-button-variant": "warning",
          "selected-variant": "warning",
          "today-variant": "warning",
          value: _vm.value
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }