var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dropdown",
    {
      ref: "dropdown",
      class: { "disabled-input-styles": _vm.disabled },
      style: { transition: "opacity 500ms ease" },
      attrs: { disabled: _vm.disabled },
      scopedSlots: _vm._u(
        [
          {
            key: "button-content",
            fn: function() {
              return [
                _vm._t("button-content", function() {
                  return [
                    !_vm.value.length
                      ? _c("span", [
                          _vm._v(" " + _vm._s(_vm.textNoSelection) + " ")
                        ])
                      : _vm._e(),
                    _vm.value.length === 1
                      ? _c("span", [
                          _vm._v(" " + _vm._s(_vm.textSingleSelection) + " ")
                        ])
                      : _vm._e(),
                    _vm.value.length > 1
                      ? _c("span", [
                          _vm._v(" " + _vm._s(_vm.textMultipleSelection) + " ")
                        ])
                      : _vm._e()
                  ]
                })
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm._t("default", function() {
        return [
          _c("b-form-checkbox-group", {
            attrs: {
              disabled: _vm.disabled,
              options: _vm.options,
              checked: _vm.value
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            }
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }