var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FilterSelection",
    {
      attrs: {
        disabled: _vm.disabled,
        "get-item-string-compare": _vm.getRegionNameById
      },
      scopedSlots: _vm._u([
        {
          key: "selection",
          fn: function(ref) {
            var item = ref.item
            return [_vm._v(" " + _vm._s(_vm.getRegionNameById(item)) + " ")]
          }
        }
      ]),
      model: {
        value: _vm.internalValue,
        callback: function($$v) {
          _vm.internalValue = $$v
        },
        expression: "internalValue"
      }
    },
    [
      _c("FormDropdown", {
        attrs: {
          options: _vm.options,
          disabled: _vm.disabled,
          "text-no-selection": "Any region",
          "text-single-selection": "1 region selected",
          "text-multiple-selection":
            _vm.internalValue.length + " regions selected"
        },
        model: {
          value: _vm.internalValue,
          callback: function($$v) {
            _vm.internalValue = $$v
          },
          expression: "internalValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }