var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LoadingController",
    {
      attrs: { loading: _vm.loading, error: _vm.error },
      on: { retry: _vm.retry }
    },
    [_vm._t("default", null, { state: _vm.state })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }