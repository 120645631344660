var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ul",
        { staticClass: "list-selected" },
        _vm._l(_vm.displayValues, function(displayValue) {
          return _c(
            "li",
            { key: displayValue.id, staticClass: "list-selected-item" },
            [
              _c(
                "div",
                {
                  staticClass: "list-selected-item__value",
                  attrs: { name: "selection" }
                },
                [_vm._t("selection", null, null, displayValue)],
                2
              ),
              !_vm.disabled
                ? _c(
                    "div",
                    { staticClass: "list-selected-item__actions" },
                    [
                      _c("BButtonClose", {
                        attrs: { "text-variant": "danger" },
                        on: {
                          click: function($event) {
                            return _vm.remove(displayValue)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        }),
        0
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }